import Header from "../components/Layout/Header";
import Contact from "../components/Contact";

const NoPage = () => {
    return (
        <>
            <Header />
            <section className="bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center my-5 py-5 text-black">
                                Uh oh! 
                                <br/>
                                Something has gone wrong
                            </h1>
                            <p className="text-black text-center">Please try again or get in touch: <a href="mailto:hello@mxcode.co.uk">hello@mxcode.co.uk</a>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </section>
            <Contact />
        </>
    );
};
export default NoPage;
