import classes from "./index.module.scss";

const Contact = () => {
  return (
      <section className={classes.contact}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-5">Contact</h2>
              <p>Want to know more? Send us a message at: <a href="mailto:hello@mxcode.co.uk">hello@mxcode.co.uk</a></p>
              
            </div>
          </div>
        </div>
      </section>
  );
};
export default Contact;
