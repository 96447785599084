import classes from "./index.module.scss";
import Fade from "react-reveal/Fade";

import Annie from "../../assets/img/Annie.jpg";
import Maria from "../../assets/img/Maria.jpg";

const About = () => {
    return (
        <section id="about" className={classes.about}>
            <div className="container">
                <div className="row">
                    <Fade left>
                        <h2 className="mb-5">About</h2>
                        <div className="col-md-9 col-12">
                            <p>
                                Established in 2022, MXCode LTD. is a software
                                development company based in Haywards Heath, but
                                as we're 100% remote we can work anywhere in the
                                world.
                            </p>
                            <div className="row pt-5">
                                <div className="col-md-6">
                                    <h3 className="pb-4">Who we are</h3>
                                    <p>
                                        Agile software engineers with a
                                        passion for writing beautiful code to
                                        create beautiful websites.
                                    </p>
                                    <p>
                                        We're a small female founded software
                                        development company. Our passion
                                        is supporting women and under-represented communities in business to achieve their online potential.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <h3 className="pb-4">What we do</h3>

                                    <p>
                                        No project is too small; from large
                                        bespoke websites to small amends, HTML
                                        emails or landing pages.
                                    </p>
                                    <p>
                                        Our skillset includes:
                                        <br />
                                        Design • Semantic HTML • CSS • SASS •
                                        PHP • JavaScript • MySQL • React •
                                        Typescript • OOP • Agile • HTML email •
                                        NodeJS • MongoDB
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="col-md-3">
                            <div className={`mb-5 ${classes.aboutImg}`}>
                                <div className="img-container">
                                    <img src={Annie} alt="Annie Fedorec-Kyte" />
                                </div>
                                <div className={classes.info}>
                                    <h6>Annie Fedorec-Kyte</h6>
                                    <p className="mb-3">
                                        Co-Founder &amp; Lead Dev
                                    </p>
                                    <p>
                                        E:{" "}
                                        <a href="mailto:annie@mxcode.co.uk">
                                            annie@mxcode.co.uk
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className={classes.aboutImg}>
                                <div className="img-container">
                                    <img src={Maria} alt="Maria Fedorec-Kyte" />
                                </div>
                                <div className={classes.info}>
                                    <h6>Maria Fedorec-Kyte</h6>
                                    <p className="mb-3">
                                        Co-Founder, Dev &amp; Scrum Master
                                    </p>
                                    <p>
                                        E:{" "}
                                        <a href="mailto:maria@mxcode.co.uk">
                                            maria@mxcode.co.uk
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
};
export default About;
