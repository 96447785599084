import Nav from "./Nav";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { Link } from "react-router-dom";

import classes from "./Header.module.scss";

const Header = () => {
    return (
        <header>
            <div className={`container-fluid pb-5  ${classes.header}`}>
                <Nav />
                <Link to="/">
                    <Logo className={classes.logo} />
                </Link>
            </div>
        </header>
    );
};
export default Header;
