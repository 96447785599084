import Header from "../components/Layout/Header";
import ProjectTile from "../components/Projects/ProjectTile";
import { projects } from "../data/project.js";

const Projects = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="row my-5">
                    <div className="col">
                        <h1>Projects</h1>
                    </div>
                </div>
                <div className="row my-5 row-gap ">
                    {projects.map((project, index) => (
                        <ProjectTile data={project} key={index}/>
                    ))}
                </div>

            </div>
        </>
    );
};
export default Projects;
