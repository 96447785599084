import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import classes from "./ProjectCarouselImages.module.scss";
import Lightroom from "react-lightbox-gallery";


const ProjectCarouselImage = (props) => {
    const masonry = props.masonry;

    return (
        <>
        <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: masonry, 900: masonry }}
        >
            <Masonry gutter={"10px"}>
                {props.content.map((image, idx) => (
                    <div className={classes.masonryContainer}>
                        <img
                            className={classes.img}
                            src={image.src}
                            alt={image.alt}
                            key={idx}
                        />
                        <div className={classes.projectData}>
                            <h4>{image.title}</h4>
                        </div>
                    </div>
                ))}
            </Masonry>
        </ResponsiveMasonry>
</>
    );
};
export default ProjectCarouselImage;
