import { Link } from "react-router-dom";
import classes from "./ProjectTile.module.scss";

const ProjectTile = ({ data }) => {
    return (
        <div className={`col-md-4`}>
            <Link
               to={'/projects/' + data.id}
            >
                <div className={classes.projectTile}>
                    <img src={data.featured} alt="" />
                    <div className={classes.projectData}>
                        <h6>{data.tech}</h6>
                        <h4>{data.title}</h4>
                    </div>
                </div>
            </Link>
        </div>
    );
};
export default ProjectTile;
