import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import Fade from 'react-reveal/Fade';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import classes from "./index.module.scss";

const Testimonials = () => {
  return (
    <Fade bottom>
    <section className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="mb-5 text-center">Nice words</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <Swiper
            pagination={{
              type: "bullets",
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            className={classes.testimonials}
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            // autoHeight={true}
          >
            <SwiperSlide>
              <div className="text-center">
                <p>
                &ldquo;Annie has been brilliant to work with. I found her very easy to talk to and explain the vision I had for my website and online shop. She really listens and just 'gets it'. I had lots of ideas and Annie was able to make them happen with her expertise, and I felt able to trust her judgment on what would and wouldn't look good.
                </p>

                <p>
                I'm very happy with the design of my website and its new functionalities - it's now a website I'm really proud of and enjoy showing to my customers. 
I won't hesitate to work with Annie for future projects.&rdquo;
                </p>
                <cite>Sarah Ellis, Sarah Ellis Coaching</cite>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-center">
                <p>
                  &ldquo;A pleasure to work with and [they] worked tirelessly to
                  create a new website for our charity within a set timeframe.
                  Annie really understood what we wanted to achieve and gave us
                  excellent guidance along the way. It made for a very easy and
                  collaborative process.
                </p>

                <p>
                  We really appreciate that Annie was always available to answer
                  our queries and to make 'just one more tweek!'. We are
                  thrilled with our website. I recommend her unreservedly for
                  her hard work, creativity, customer care and patience in
                  abundance.&rdquo;
                </p>
                <cite>M Curran, St. Peter&rsquo;s Pre-School Ardingly</cite>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-center">
                <p>
                  &ldquo;Annie recently redid and rebranded my whole website and
                  brand. I honestly couldn&rsquo;t be happier with the finished
                  product. The whole process was really smooth and
                  uncomplicated. Anything I asked for was possible or could be
                  explained. Despite probably constantly using the wrong
                  terminology, Annie helped come up with ideas and designs until
                  I felt it was exactly what I wanted.
                </p>
                <p>Thank you so much Annie!&rdquo;</p>
                <cite>Ylva, Yoga with Ylva</cite>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-center">
                <p>
                  &ldquo;Annie was an absolute dream to work with, nothing was
                  ever too much trouble. At first I was reluctant to have a
                  website for my new business as I didn't want to deal with the
                  hassle of creating one, however I found the whole process
                  hassle free and Annie handled everything. I've ended up with
                  an amazing website that has helped me gain many new clients.
                </p>
                <p>
                  Annie was so helpful and helped me create a business domain
                  and sorted my hosting - none of which I knew I'd need! We had
                  several calls where she listened to what I needed and then
                  made some brilliant suggestions!
                </p>
                <p>
                  Many of my clients have commented on how much they love my
                  website and I'm proud of the first impression it gives of my
                  business. I would highly recommend Annie, she is incredibly
                  professional and has such a friendly manner that you instantly
                  feel at ease in what could be a daunting situation!&rdquo;
                </p>
                <cite>Emily, Emily King Counselling</cite>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
    </Fade>
  );
};
export default Testimonials;
