import { Link } from "react-router-dom";
import classes from "./Button.module.scss";

const Button = (props) => {
    if (props.self) {
        console.log(props.link)
        return (
            <Link to={props.link} className={classes.button}>
                {props.children}
            </Link>
        );
    } else {
        return (
            <a
                href={props.link}
                target="_blank"
                rel="noreferrer"
                className={classes.button}
            >
                {props.children}
            </a>
        );
    }
};
export default Button;
