import classes from './Footer.module.scss';
const Footer = () => {
  return (
    <section className={`container ${classes.footer}`}>
      <div className="row">
        <div className="col-md-6 col-12 text-center text-md-start">
          <p>MXcode Ltd. &copy; {new Date().getFullYear()} </p>
        </div>
        <div className="col-md-6 col-12 pt-2 pt-md-0 text-center text-md-end">
          <p>Company Registration number: 14073496</p>
        </div>
      </div>
    </section>
  );
};
export default Footer;
