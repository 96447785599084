import Flip from "react-reveal/Flip";
import Nav from "./Nav";
import Video from "../../assets/video/bg-video.mp4";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";

import classes from "./HomeHeader.module.scss";

const HomeHeader = () => {
  return (
    <header>
      <video autoPlay muted loop playsInline className={classes.bgVideo}>
        <source src={Video} type="video/mp4" />
      </video>
      <div className={`container-fluid  ${classes.header}`}>
        <Nav />
        <div className={classes.logo}>
          <Logo />
        </div>
        <h1>
          We build beautiful websites
        </h1>
        <Flip bottom>
          <a href="#projects" className="text-center py-5">
            <span>Read</span> more
          </a>
        </Flip>
      </div>
    </header>
  );
};
export default HomeHeader;
