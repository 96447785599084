import HomeHeader from "../components/Layout/HomeHeader";
import Projects from "../components/Projects";
import About from "../components/About";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";

const Home = () => {
  return (
    <>
      <HomeHeader />
      <Projects />
      <About />
      <Testimonials />
      <Contact />
    </>
  );
};
export default Home;
