import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { projects } from "../../data/project.js";
import Button from "../ui/Button";
import ProjectCarouselImages from "./ProjectCarouselImages";

import classes from "./ProjectCarousel.module.scss";

const ProjectCarousel = () => {
    return (
        <Swiper
            pagination={{
                type: "bullets",
                clickable: true,
            }}
            navigation={{
                prevEl: "#prev_slide",
                nextEl: "#next_slide",
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className={classes.projectSwiper}
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            
        >
            <div id="prev_slide" className="swiper-button-prev"></div>
            <div id="next_slide" className="swiper-button-next"></div>

            {projects.slice(0, 5).map((project, index) => (
                <SwiperSlide key={index}>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="row justify-content-end">
                                <div className="col-md-10 align-self-center">
                                    <div
                                        id="projectInfo"
                                        className={classes.info}
                                    >
                                        <h3 className="">{project.title}</h3>
                                        <p className="mb-5 fs-6">
                                            <small>{project.date}</small>
                                        </p>
                                        <h4>The Project</h4>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: project.description,
                                            }}
                                        />
                                        <h4>The Tech</h4>
                                        <p>{project.tech}</p>

                                        <div className="py-5">
                                            <Button link={project.url}>
                                                Visit Website
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 offset-md-1">
                            <ProjectCarouselImages
                                key={index}
                                content={project.images}
                                link={project.url}
                                masonry={project.masonry}
                            />
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
export default ProjectCarousel;
