import classes from "./index.module.scss";
import ProjectCarousel from "./ProjectCarousel";
import Slide from "react-reveal/Slide";
import Button from "../ui/Button";

const Projects = () => {
    return (
        <section className={classes.projects} id="projects">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-12">
                        <div className="row justify-content-end">
                            <div className="col-md-10">
                                <h2>Our Work</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Slide bottom>
                <div className="container-fluid">
                    <ProjectCarousel />
                    <div className="row mt-5">
                        <div className="col text-center mt-5">
                            <Button link="/projects" self>View more projects</Button>
                        </div>
                    </div>
                </div>
            </Slide>
        </section>
    );
};
export default Projects;
