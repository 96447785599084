import img1 from "../assets/img/dunley-2021/home.jpg";
import img2 from "../assets/img/dunley-2021/rooms.jpg";
import img3 from "../assets/img/dunley-2021/testimonial.jpg";
import img7 from "../assets/img/dunley-2021/home1.jpg";
import img8 from "../assets/img/dunley-2021/testimonial2.jpg";
import img9 from "../assets/img/dunley-2021/home2.jpg";

import img4 from "../assets/img/st-peters/new-home.jpg";
import img5 from "../assets/img/st-peters/new-about.jpg";
import img6 from "../assets/img/st-peters/new-gallery.jpg";
import img4o from "../assets/img/st-peters/old-home.jpg";
import img5o from "../assets/img/st-peters/old-about.jpg";
import img6o from "../assets/img/st-peters/old-gallery.jpg";

import about from "../assets/img/CoS/about-carousel.jpg";
import quiz from "../assets/img/CoS/quiz.jpg";
import home1 from "../assets/img/CoS/home-1.jpg";
import home3 from "../assets/img/CoS/home-3.jpg";
import home2mb from "../assets/img/CoS/home-2-mob.jpg";
import homemb from "../assets/img/CoS/home-mob.jpg";
import homemain from "../assets/img/CoS/home-main.jpg";

import yoga1 from "../assets/img/yoga-with-ylva/new-home.png";
import yoga2 from "../assets/img/yoga-with-ylva/new-equipment.jpg";
import yoga3 from "../assets/img/yoga-with-ylva/new-class.png";
import yoga4 from "../assets/img/yoga-with-ylva/old-class.png";
import yoga5 from "../assets/img/yoga-with-ylva/old-home.png";
import yoga6 from "../assets/img/yoga-with-ylva/old-equipment.png";

import emily1 from "../assets/img/emily-king/home.jpg";
import emily2 from "../assets/img/emily-king/contact.jpg";
import emily3 from "../assets/img/emily-king/about-mb.jpg";
import emily4 from "../assets/img/emily-king/services-mb.jpg";
import emily5 from "../assets/img/emily-king/services.jpg";
import emily6 from "../assets/img/emily-king/location.jpg";
import emily7 from "../assets/img/emily-king/home-mb.jpg";

import mex1 from "../assets/img/mexicantina/mexicantina.jpg";
import mex2 from "../assets/img/mexicantina/checkout.jpg";
import mex3 from "../assets/img/mexicantina/menu.jpg";
import mex4 from "../assets/img/mexicantina/add-to-order.jpg";

import flinders1 from "../assets/img/flinders/flinders.jpg";

import rewards1 from "../assets/img/rewards/rewards.jpg";

import eastcheap1 from "../assets/img/eastcheap-court/home.jpg";
import eastcheap2 from "../assets/img/eastcheap-court/home-mb.jpg";
import eastcheap3 from "../assets/img/eastcheap-court/smart.jpg";
import eastcheap4 from "../assets/img/eastcheap-court/table.jpg";
import eastcheap5 from "../assets/img/eastcheap-court/accom.jpg";
import eastcheap6 from "../assets/img/eastcheap-court/connection.jpg";

import corsham1 from "../assets/img/corsham/home.jpg";
import corsham2 from "../assets/img/corsham/journey.jpg";
import corsham3 from "../assets/img/corsham/building.jpg";

import sarah1 from "../assets/img/sarah/sarah-1.jpg";
import sarah2 from "../assets/img/sarah/sarah-2.jpg";
import sarah3 from "../assets/img/sarah/sarah-3.jpg";
import sarah4 from "../assets/img/sarah/sarah-4.jpg";
import sarah5 from "../assets/img/sarah/sarah-5.jpg";
import sarah6 from "../assets/img/sarah/sarah-6.jpg";

import finn1 from "../assets/img/finns-swim-school/finn-1.jpg";
import finn2 from "../assets/img/finns-swim-school/finn-2.jpg";
import finn3 from "../assets/img/finns-swim-school/finn-3.jpg";
import finn4 from "../assets/img/finns-swim-school/finn-4.jpg";
import finn5 from "../assets/img/finns-swim-school/finn-5.jpg";
import finn6 from "../assets/img/finns-swim-school/finn-6.jpg";

import four1 from "../assets/img/four-trees/four-1.jpg";
import four2 from "../assets/img/four-trees/four-2.jpg";
import four3 from "../assets/img/four-trees/four-3.jpg";

import ideias1 from "../assets/img/ideias/ideias-1.jpg";
import ideias2 from "../assets/img/ideias/ideias-2.jpg";
import ideias3 from "../assets/img/ideias/ideias-3.jpg";

import stronger1 from "../assets/img/stronger-foundations/stronger-1.jpg";
import stronger2 from "../assets/img/stronger-foundations/stronger-2.jpg";
import stronger3 from "../assets/img/stronger-foundations/stronger-3.jpg";
import stronger4 from "../assets/img/stronger-foundations/stronger-4.jpg";
import stronger5 from "../assets/img/stronger-foundations/stronger-5.jpg";
import stronger6 from "../assets/img/stronger-foundations/stronger-6.jpg";


export const projects = [
  
    // {
    //     title: "Ideias",
    //     id: "15",
    //     date: "September 2023",
    //     description:
    //         "<p>Create a new website from supplied designs to allow client to easily update portfolio of work.</p>",
    //     url: "https://ideias.co.uk/",
    //     tech: "Wordpress",
    //     masonry: "2",
    //     featured: ideias1,
    //     images: [
    //         {
    //             src: ideias1,
    //             alt: "Home screenshot",
    //             title: "Homepage desktop"
    //         },
    //         {
    //             src: ideias2,
    //             alt: "Class finder",
    //             title: "Case study mobile"
    //         },
    //         {
    //             src: ideias3,
    //             alt: "About page",
    //             title: "About page desktop"
    //         },
    //     ],
    // },
    {
        title: "Stronger Foundations",
        id: "14",
        date: "August 2023",
        description:
            "<p>We worked closely with the designer on this project to completely overhaul the existing website to align it with their new branding and tone of voice. We built a bespoke theme Wordpress site, using ACF fields.</p>",
        url: "https://ideias.co.uk/",
        tech: "Wordpress, ACF, Bootstrap, Javascript",
        masonry: "3",
        featured: stronger1,
        images: [
            {
                src: stronger1,
                alt: "Home screenshot",
                title: "Homepage desktop"
            },
            {
                src: stronger2,
                alt: "Class finder",
                title: "Homepage mobile"
            },
            {
                src: stronger3,
                alt: "Resources",
                title: "Resources desktop"
            },
            {
                src: stronger4,
                alt: "Map",
                title: "Map mobile"
            },
            {
                src: stronger5,
                alt: "About page",
                title: "About page desktop"
            },
            {
                src: stronger6,
                alt: "Single resource",
                title: "Single resource mobile"
            },
        ],
    },
    {
        title: "Finns Swim School",
        id: "13",
        date: "July 2023",
        description:
            "<p>To update the look and feel of the existing site, make it easier to navigate and find suitable swimming lessons.</p><p>We designed and built a Wordpress site with a custom theme using ACF to make make the site easy for the client to maintain and update.</p>",
        url: "https://finnsswimschool.co.uk/",
        tech: "Wordpress, Advanced Custom Fields (ACF), Bootstrap",
        masonry: "3",
        featured: finn1,
        images: [
            {
                src: finn1,
                alt: "Home screenshot",
                title: "Homepage desktop"
            },
            {
                src: finn2,
                alt: "Class finder",
                title: "Class finder mobile"
            },
            {
                src: finn3,
                alt: "Class finder",
                title: "Class finder desktop"
            },
            {
                src: finn4,
                alt: "Home screenshot",
                title: "Homepage mobile"
            },
            {
                src: finn5,
                alt: "Teachers screenshot",
                title: "Meet the teachers desktop"
            },
            {
                src: finn6,
                alt: "Pools screenshot",
                title: "Pools overview mobile"
            },
           
        ],
    },
    {
        title: "FourTrees Capital Management",
        id: "12",
        date: "March 2023",
        description:
            "<p>To build a Wordpress site from the supplied design in order to showcase FourTrees Capital's investment portfolio.</p>",
        url: "https://fourtreescap.com//",
        tech: "Wordpress",
        masonry: "2",
        featured: four1,
        images: [
            {
                src: four1,
                alt: "Home screenshot",
                title: "Homepage desktop"
            },
            {
                src: four3,
                alt: "Home screenshot",
                title: "Homepage mobile"
            },
            {
                src: four2,
                alt: "Portfolio screenshot",
                title: "Screenshot desktop"
            },
        ],
    },
    {
        title: "Sarah Ellis Coaching",
        id: "11",
        date: "November 2022",
        description:
            "<p>To redesign the existing site to update the look and feel, add new functionality to allow customers to purchase coaching packages on the website.</p><p>We built a Wordpress site that uses WooCommerce.</p>",
        url: "https://sarahelliscoaching.co.uk/",
        tech: "Wordpress, Woocommerce, Bootstrap",
        masonry: "3",
        featured: sarah1,
        images: [
            {
                src: sarah1,
                alt: "Home screenshot",
                title: "Homepage desktop"
            },
            {
                src: sarah2,
                alt: "Follow section",
                title: "Home on mobile"
            },
            {
                src: sarah3,
                alt: "Contact page screenshot",
                title: "Contact"
            },
            {
                src: sarah4,
                alt: "Client page screenshot",
                title: "Client page"
            },
            {
                src: sarah6,
                alt: "Testimonials",
                title: "Testimonials"
            },
            {
                src: sarah5,
                alt: "About",
                title: "About"
            },
           
        ],
    },
    {
        title: "Eastcheap Court",
        id: "10",
        date: "July 2022",
        description:
            "<p>To create a responsive site from the supplied design</p><p>We built a pixel-perfect HTML site, using Bootstrap and Javascript</p>",
        url: "https://eastcheapcourt.london/",
        tech: "HTML5, Bootstrap, JavaScript",
        masonry: "3",
        featured: eastcheap1,
        images: [
            {
                src: eastcheap1,
                alt: "Home screenshot",
                title: "Homepage mobile"
            },
            {
                src: eastcheap2,
                alt: "Home",
                title: "Home on mobile"
            },
            {
                src: eastcheap4,
                alt: "Layout screenshot",
                title: "Table of data"
            },
            {
                src: eastcheap3,
                alt: "Smart",
                title: "Scrolling diagram"
            },
            {
                src: eastcheap6,
                alt: "Homepage",
                title: "Connection info"
            },
            {
                src: eastcheap5,
                alt: "Table of data",
            },
        ],
    },
    {
        title: "The Corsham",
        id: "9",
        date: "July 2022",
        description:
            "<p>To create a responsive site from the supplied design</p><p>We built a pixel-perfect HTML site, using Bootstrap and Javascript</p>",
        url: "https://thecorsham.co.uk/",
        tech: "HTML5, Bootstrap, JavaScript",
        masonry: "2",
        featured: corsham1,
        images: [
            {
                src: corsham1,
                alt: "Home screenshot",
                title: "Home"
            },
            {
                src: corsham2,
                alt: "Home",
                title: "Journey times"
            },
            {
                src: corsham3,
                alt: "Layout screenshot",
                title: "Building info"
            },
        ],
    },
    {
        title: "Circle of Style",
        id: "1",
        date: "July 2022",
        description:
            "<p>To update website UI to reflect new tone of voice and target demographic. To add new features and improve user experience</p><p>We built pixel-perfect UI components from the supplied design. In collaboration with the backend team, we updated the quiz user journey to reduce steps and increase conversions.</p>",
        url: "https://circleofstyle.com/",
        tech: "React.js / NextJS / TypeScript / SASS / Best practice SEO",
        masonry: "3",
        featured: homemain,
        images: [
            {
                src: homemb,
                alt: "Home screenshot",
                title: "Homepage mobile"
            },
            {
                src: about,
                alt: "About screenshot",
                title: "About"
            },
            {
                src: quiz,
                alt: "Quiz screenshot",
                title: "Quiz"
            },
            {
                src: home1,
                alt: "Homepage",
                title: "Homepage"
            },
            {
                src: home2mb,
                alt: "Homepage",
                title: "Homepage"
            },
            {
                src: home3,
                alt: "Homepage",
            },
        ],
    },
    {
        title: "Dunley Farm",
        id: "2",
        date: "April 2021",
        description:
            "<p>To update the existing website, modernise the look and feel whilst keeping brand identity the same.</p><p>We updated the layout to make it easier to book, read reviews and view images of the accommodation. We integrated the Tripadvisor review app so that reviews will pull automatically from Tripadvisor to save the client having to manually add them.</p>",
        url: "https://www.dunleyfarm.co.uk/",
        tech: "Wordpress / SASS / Best practice SEO",
        masonry: "3",
        featured: img1,
        images: [
            {
                src: img1,
                alt: "Dunley Homepage",
                title: "Homepage"
            },
            {
                src: img8,
                alt: "Dunley Testimonial page",
                title: "Introduction"
            },
            {
                src: img2,
                alt: "Dunley Room page",
                title: "Rooms"
            },
            {
                src: img3,
                alt: "Dunley Testimonial page",
                title: "Testimonials"
            },
            {
                src: img7,
                alt: "Dunley Testimonial page",
                title: "Homepage"
            },
            {
                src: img9,
                alt: "Dunley Testimonial page",
                title: "Homepage - mobile"
            },
        ],
    },
    {
        title: "St. Peters Pre-School Ardingly",
        id: "3",
        date: "March 2021",
        description:
            "<p>To update their existing website to improve the user journey, make it responsive on small screens and showcase their offering to attract new families.</p><p>A major pain point from the old site was the inability to update it, so we created a custom Wordpress site. We simplified the navigation and reduced the amount of content on the homepage to make it easier for visitors to get a quick overview of the preschool.</p>",
        url: "https://st-peters-preschool-ardingly.org/",
        tech: "Wordpress / SASS / Best practice SEO",
        masonry: "2",
        featured: img4,
        images: [
            {
                src: img4,
                alt: "Ardingly Homepage",
                title: "New Homepage"
            },
            {
                src: img4o,
                alt: "Ardingly Homepage",
                title: "Old Homepage"
            },
            {
                src: img5,
                alt: "Ardingly About",
                title: "New About"
            },
            {
                src: img5o,
                alt: "Ardingly About",
                title: "Old About"
            },
            {
                src: img6,
                alt: "Ardingly Gallery",
                title: "New Gallery"
            },
            {
                src: img6o,
                alt: "Ardingly Gallery",
                title: "Old Gallery"
            },
        ],
    },
    {
        title: "Flinders Coffee",
        id: "7",
        date: "December 2020",
        description:
            "<p>To create a website for a local coffee shop. It needed to allow visitors to pre-order and get an overview of the cafe.</p><p>We created a bespoke Wordpress site that displayed the menu and integrated with a third-party ordering service.</p>",
        url: "https://flinderscoffee.co.uk/",
        tech: "Website Design / Wordpress",
        masonry: "1",
        featured: flinders1,
        images: [
            {
                src: flinders1,
                alt: "Flinders Homepage",
                title: "Homepage"
            },
        ],
    },
    {
        title: "Yoga with Ylva",
        id: "4",
        date: "November 2020",
        description:
            "<p>To refresh their existing website to improve the user journey and incorporate the theme of movement on the site.</p><p>We created a bespoke Wordpress site that showcased the client's beautiful imagery and added subtle animations to create movement.</p>",
        url: "https://yogawithylva.com/",
        tech: "Website Design / Wordpress",
        masonry: "2",
        featured: yoga1,
        images: [
            {
                src: yoga1,
                alt: "Yoga with Yvla Homepage",
                title: "New Homepage"
            },
            {
                src: yoga2,
                alt: "Yoga with Yvla Equipment page",
                title: "New Equipment"
            },
            {
                src: yoga3,
                alt: "Yoga with Yvla Class page",
                title: "New class"
            },
            {
                src: yoga4,
                alt: "Yoga with Yvla Homepage",
                title: "Old home"
            },
            {
                src: yoga5,
                alt: "Yoga with Yvla Homepage",
                title: "Old class"
            },
            {
                src: yoga6,
                alt: "Yoga with Yvla Homepage",
                title: "Old equipment"
            },
        ],
    },
    {
        title: "Emily King Counselling",
        id: "5",
        date: "June 2020",
        description:
            "<p>To create an online presence for this start-up.</p><p>We designed and built a custom React site, based on calm colours and simple videos. The site gives an overview of the services available and an easy way to make enquiries.</p>",
        url: "https://emilykingcounselling.co.uk/",
        tech: "Website Design / React",
        masonry: "3",
        featured: emily1,
        images: [
            {
                src: emily7,
                alt: "Emily King Counselling Homepage",
                title: "Homepage"
            },
            {
                src: emily2,
                alt: "Emily King Counselling Homepage",
                title: "Contact"
            },
            {
                src: emily3,
                alt: "Emily King Counselling Homepage",
                title: "About"
            },
            {
                src: emily5,
                alt: "Emily King Counselling Homepage",
                title: "Pricing"
            },
            {
                src: emily4,
                alt: "Emily King Counselling Homepage",
                title: "Services"
            },

            {
                src: emily6,
                alt: "Emily King Counselling Homepage",
                title: "Location"
            },
        ],
    },
    {
        title: "Mexicantina",
        id: "6",
        date: "May 2020",
        description:
            "<p>At the start of Covid, a local Coffee shop decided to expand their offering to include a mexican takeaway business. They needed a website to display the menu and somewhere for customer's to order from.</p><p>We designed and built a custom Wordpress site, custom plugins allowed customers to view the menu, place an order and select a delivery or collection time-slot. These were limited and first-come, first serve. On completion of the transaction, the order is printed on the kitchen's thermal printer.</p>",
        tech: "Website Design / Wordpress",
        masonry: "2",
        featured: mex1,
        images: [
            {
                src: mex1,
                alt: "Mexicantina Homepage",
                title: "Homepage"
            },
            {
                src: mex2,
                alt: "Mexicantina Homepage",
                title: "Checkout"
            },
            {
                src: mex3,
                alt: "Mexicantina Homepage",
                title: "Menu"
            },
            {
                src: mex4,
                alt: "Mexicantina Homepage",
                title: "Menu item"
            },
        ],
    },
    {
        title: "Rewards Bag",
        id: "8",
        date: "July 2019",
        description:
            "<p>The client had a new website design and needed to update their current website to reflect the new style. The client wanted the CMS to remain unchanged to ensure they could continue to update and create new content.</p><p>We maintained the structure of the client's current Wordpress website but created new custom ACF modules for each new section required. Allowing the client to continue maintaining their site as they had been, with minimal training required for the new sections.</p><p>To create animation, we used a combination of CSS3 animations, Javascript and SVG animations. I adopted a different approach for each animation depending on the interaction, play time and its requirements.</p><p>The site is fully responsive and users can switch between German and English when using the site.</p>",
        tech: "Wordpress / ACF / Animation",
        masonry: "1",
        featured: rewards1,
        images: [
            {
                src: rewards1,
                alt: "Rewards Bag Homepage",
                title: "Homepage"
            },
        ],
    },
];
