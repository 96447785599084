import { useState } from "react";
import { Link } from "react-router-dom";

import classes from "./Nav.module.scss";

const Nav = () => {
  const [isNavOpen, setisNavOpen] = useState(false);

  const handleMenuClick = () => setisNavOpen(!isNavOpen);

  return (
    <>
      <div
        className={classes.nav + " " + (isNavOpen ? `${classes.NavOpen}` : "")}
        onClick={handleMenuClick}
      >
        <div className={classes.menu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={classes.close}>
          <span></span>
          <span></span>
        </div>
      </div>
      <nav className={`${isNavOpen && classes.show} ${classes.navContent}`}>
        <ul>
          <li>
            <Link to="/"  onClick={handleMenuClick}>Home</Link>
          </li>
          <li>
            <Link to="/projects"  onClick={handleMenuClick}>projects</Link>
          </li>
          {/* <li>
            <Link to="/contact"  onClick={handleMenuClick}>contact</Link>
          </li> */}
        </ul>
      </nav>
    </>
  );
};
export default Nav;
