import { Link, useParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import { projects } from "../data/project.js";
import Button from "../components/ui/Button";
import ProjectCarouselImages from "../components/Projects/ProjectCarouselImages";

const ProjectSingle = () => {
    const idParam = useParams().id;
    const project = projects.find((projects) => projects.id === idParam);
    return (
        <>
            <Header />
            <div className="container">
                <div className="row my-3">
                    <div className="col-12">
                        <Link to="/projects">
                            <small>&lt; View all projects</small>
                        </Link>
                        <h1 className="mt-5">{project.title}</h1>
                        <p className="mb-5 fs-6">
                            <small>{project.date}</small>
                        </p>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-4">
                        <h4>The Project</h4>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: project.description,
                            }}
                        />
                        <h4>The Tech</h4>
                        <p>{project.tech}</p>

                        <div className="py-5">
                            <Button link={project.url}>Visit Website</Button>
                        </div>
                    </div>
                    <div className="col-md-7 offset-md-1">
                        <ProjectCarouselImages
                            content={project.images}
                            link={project.url}
                            masonry={project.masonry}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default ProjectSingle;
